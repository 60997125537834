$theme: ekonomi;
@use "design-system" as ds;

.debugPage {
  @include ds.stack(regular);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ds.spacing(base) auto ds.spacing() auto;
  width: 100%;
  flex-grow: 0;
  flex-shrink: 1;
  box-sizing: border-box;
  padding: ds.spacing("large") ds.spacing() 0 ds.spacing();

  @media (min-width: ds.$screen-size--small) {
    max-width: ds.$content-width--max;
    @include ds.pad(large);
  }
}

.illustration {
  text-align: center;
  animation: wiggle 2s linear infinite;
}

.pageTitle {
  text-align: center;
  @include ds.stack(regular);
  @include ds.typography(h2, $theme: $theme);
  @include ds.text-color(primary, $theme: $theme);
}

.text {
  @include ds.stack(regular);
  @include ds.typography(body, $theme: $theme);
  text-align: center;
}

.wrapper {
  @include ds.background(primary, $theme: $theme);
  @include ds.border(divider--subtle, $a: 1px, $theme: $theme);
  margin: ds.spacing("regular-increased");
  padding: ds.spacing("regular-increased");
}

.property {
  @include ds.stack(micro);
  @include ds.typography(body, $theme: $theme);
  text-align: center;
}

.valueNeutral {
  font-weight: bolder;
}

.valueTruthy {
  font-weight: bolder;
  color: darkgreen;
}

.valueFalsy {
  font-weight: bolder;
  color: darkred;
}

.toLoginButton {
  @include ds.stack(large);
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  gap: ds.spacing("regular");
}

@keyframes wiggle {
  0% {
    transform: rotate(-2deg);
  }
  25% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(2deg);
  }
  75% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-2deg);
  }
}
